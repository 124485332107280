import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
  } from "../../../components/ui/dialog"
  import { Button } from "../../../components/ui/button"
  import { Input } from "../../../components/ui/input"
  import { Label } from "../../../components/ui/label"
  import { Mail, Phone } from "lucide-react"
  import { useState, useContext } from "react"
  import { LeadGenContext } from '../../../contexts/LeadGenContext'
  
  export function ContactDialog({ name, phone, email, status, leadId, notes: initialNotes }) {
    const { setLeadMapping } = useContext(LeadGenContext)
    const [notes, setNotes] = useState(initialNotes)
    const [selectedStatus, setSelectedStatus] = useState(status)
    
    const statuses = [
      "Prospective Opportunity",
      "Contacted", 
      "Confirmed Lead",
      "Converted Customer"
    ]
  
    const handleSubmit = async (e) => {
      e.preventDefault()
      await setLeadMapping(leadId, selectedStatus, notes)
    }
  
    return (
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Contact {name}</DialogTitle>
        </DialogHeader>
  
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <div className="flex items-center gap-2 mb-2">
              <Phone className="w-4 h-4" />
              <span>{phone}</span>
            </div>
            <div className="flex items-center gap-2">
              <Mail className="w-4 h-4" />
              <span>{email}</span>
            </div>
          </div>
  
          <div className="space-y-2">
            <Label>Lead Status</Label>
            <div className="flex flex-col gap-2">
              {statuses.map(s => (
                <div className="flex items-center" key={s}>
                  <input
                    type="radio" 
                    id={s}
                    checked={selectedStatus === s}
                    onChange={() => setSelectedStatus(s)}
                    className="h-4 w-4"
                  />
                  <label htmlFor={s} className="ml-2">{s}</label>
                </div>
              ))}
            </div>
          </div>
  
          <div className="space-y-2">
            <Label htmlFor="notes">Notes</Label>
            <Input
              id="notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Add any notes..."
            />
          </div>
  
          <div className="flex gap-2">
            <Button type="submit" className="w-full">Update Status</Button>
            <Button 
              type="button"
              variant="outline"
              onClick={() => window.location.href = `tel:${phone}`}
              className="w-full"
            >
              Call Now
            </Button>
          </div>
        </form>
      </DialogContent>
    )
  }