import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { AppContext } from 'contexts/AppContext';
import { getAccessToken } from 'utils/authUtils'

const AuthGuard = ({ children }) => {
  const { testing } = useContext(AppContext);

  if (testing) return children
  
  const accessToken = getAccessToken(false);
  if (accessToken && (accessToken!="eyJraWQiOiJLTHpSQmN1XzVySHpmUVV2NXo5Q25CR0NpV1RrZ1g4ZEFrNjBKd3lTZ1djIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHUxMHZndW5vbXZ4cVEzaDJwOCIsIm5hbWUiOiJOYWRpYSBIb3NzYWluIiwiZW1haWwiOiJOYWRpYS5Ib3NzYWluQHNyc2Rpc3RyaWJ1dGlvbi5jb20iLCJ2ZXIiOjEsImlzcyI6Imh0dHBzOi8vc3JzLm9rdGEuY29tL29hdXRoMi9hdXN2b2pndjl4dnpDU0RpWDJwNyIsImF1ZCI6IjBvYXY2eWFkMDdSa3NlNzVaMnA3IiwiaWF0IjoxNzM5Mzc0MTkxLCJleHAiOjE3MzkzNzc3OTEsImp0aSI6IklELlZNZ1FxSnp1VU91eUU5N0t6ZDhPREV6RzctTExiTXdROVV5eDREVzNvNUkiLCJhbXIiOlsibWZhIiwib3RwIiwicHdkIl0sImlkcCI6IjAwbzE2emp3ZWNDRnU3cFVqMnA3Iiwibm9uY2UiOiIxWjRhOU9hZlo1dnVyUGxMMzJ5WEJaTzQxSDVQUVZoaHJsV3doVmVHTXJJTU5lVW5wSlFIZjJoVkxQNEQ2dXI1IiwicHJlZmVycmVkX3VzZXJuYW1lIjoiTkgxMTAwMzZAbW1oZmdiLmNvbSIsImF1dGhfdGltZSI6MTczOTM3NDE5MCwiYXRfaGFzaCI6IkoyajJGZWR3ZlNtaUdmZWZfc1N4aXcifQ.VJMMLanF3LXG75p-OWpq4UfRx8lydtp8kutJMoJ_6DkObg_N3-ZX4e2k46tcjNRCH3gAOw0pJRjoFd3-RVJTphlfMYfBULEzXMp9KWZkJlbfYMAl5smNeb2DZ_89nTHz3JWK76ktvl-ZotevBdn1D5hCWLXCfz4Kv9lJ8Tw6HotgT4Fv_CCUlU8rqPKrln9yV0rDCz4VOvK7rp9eu8-spb98y_XyJI2rzneCaUNpJ9uA9rekaQXEq6hVZ0nXPmCQvf87IGCut_Hmrq58R04yEMvkeHScqBU72mGKt-uvLHFTG0O-B1Y-Bo4FimTCDmx3ERz9VAnxFVLU72h4UKQyGA")) {
    return children;
  }
  
  const { authState, oktaAuth } = useOktaAuth();

  const isAuthenticated = useMemo(() => authState?.isAuthenticated, [authState]);
  const isLoading = useMemo(() => !authState, [authState]);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const initToken = async () => {
        try {
          await oktaAuth.tokenManager.renew('idToken');
          const tokenResponse = await oktaAuth.tokenManager.get('idToken');
          
          if (!tokenResponse?.value) {
            console.error('No valid token value');
            return;
          }
  
          localStorage.setItem('okta-token-storage', JSON.stringify({
            idToken: {
              idToken: tokenResponse.value,
              claims: tokenResponse.claims,
              expiresAt: tokenResponse.expiresAt
            }
          }));
        } catch (error) {
          console.error('Token initialization failed:', error);
          oktaAuth.signOut();
        }
      };
      initToken();
    }
  }, [authState?.isAuthenticated]);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (!isAuthenticated && !isLoading && !currentUrl.includes('/login/callback')) {
      oktaAuth.signInWithRedirect();
    }
  }, [isAuthenticated, isLoading, oktaAuth]);

  if (isLoading || !isAuthenticated ) return null;

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(AuthGuard);
