import React, { useContext } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { Security, LoginCallback } from "@okta/okta-react"
import { OktaAuth } from '@okta/okta-auth-js';
import { AppContext } from 'contexts/AppContext';
import App from './App';

export const OktaSecurityProvider = () => {
    const navigate = useNavigate();
  
    const oktaAuth = new OktaAuth({
      issuer: "https://srs.okta.com/oauth2/ausvojgv9xvzCSDiX2p7",
      clientId: "0oauz29oprnTQrlVU2p7",
      redirectUri: "https://cosailor.instalily.ai/login/callback",
      scopes: ['openid', 'profile', 'email'],
      pkce: true,
      tokenManager: {
        storage: 'localStorage'
      }
    });
  
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
      navigate(originalUri || '/', { replace: true });
    };
  
    return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <App/>
      </Security>
    );
};

export const RenderApp = () => {
  const { testing } = useContext(AppContext);

  if (testing) {
    return <App />;
  }

  return (
      <OktaSecurityProvider />
  );
};

/* export const RenderApp = () => {
  return (
      <App />
  );
}; */
