import React, { useState, useMemo, useEffect, useContext } from "react";
import { Card, CardHeader, CardContent } from "components/ui/card";
import { Button } from "components/ui/button";
import { Textarea } from "components/ui/textarea";
import { Label } from "components/ui/label";
import { Bold, Italic, Underline, Sparkles, Copy, Send } from "lucide-react";
import { ToggleGroup, ToggleGroupItem } from "components/ui/toggle-group";
import SendEmailDialog from "./send-email-dialog.js";
import { SearchAccountSwitch } from "components/layout/SearchAccountSwitch.js";
import { ChatContext } from "contexts/ChatContext";
import { AppContext } from "contexts/AppContext";
import { DataContext } from "contexts/DataContext";
import { ThumbsDown, ThumbsUp } from "lucide-react";
import { Loader2 } from "lucide-react";

export default function EmailPage() {
  const [selectedAccount, setSelectedAccount] = useState("");
  const {
    generateEmail,
    outputText,
    emailLoading,
    emailFeedback,
    handleEmailFeedback,
    formattedEmail,
  } = useContext(ChatContext);
  const { logEvent } = useContext(AppContext);
  const { customerReportData } = useContext(DataContext);

  const [promptText, setPromptText] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Get customer name when selectedAccount changes
  const customerData = useMemo(
    () =>
      customerReportData?.find((account) => account.custcd === selectedAccount),
    [selectedAccount, customerReportData]
  );

  const handleInputChange = (e) => {
    setPromptText(e.target.value);
  };

  const handleFormat = (command) => {
    document.execCommand(command, false, null);
  };

  const copyToClipboard = () => {
    logEvent("Email", "Copy to Clipboard", {
      email: outputText,
      prompt: promptText,
    });

    const outputElement = document.getElementById("outputText");

    const range = document.createRange();
    range.selectNodeContents(outputElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand("copy");
      alert("Copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }

    selection.removeAllRanges();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <div className="flex flex-wrap w-full pt-4">
        <Card className="w-full max-w-[800px] mx-auto">
          <CardHeader>
            <h2 className="text-lg font-semibold">Email Generator</h2>
          </CardHeader>
          <CardContent>
            <div className="mb-4">
              <Label htmlFor="prompt">Select a Customer: (optional)</Label>
              <SearchAccountSwitch
                className="mt-4 z-50"
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
              />
            </div>
            <div className="mb-4">
              <Label htmlFor="prompt">
                Specify the email you'd like to generate:
              </Label>
              <Textarea
                id="prompt"
                name="input"
                placeholder="Example: 'Write me an email addressing the customer's SRS anniversary.'"
                value={promptText}
                onChange={handleInputChange}
              />
            </div>
            <Button
              className={`w-full sm:w-auto mb-4 ${
                emailLoading ? "animate-pulse" : ""
              }`}
              onClick={() => generateEmail(selectedAccount, promptText)}
              disabled={emailLoading}
            >
              {emailLoading ? (
                <>
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  Generating...
                </>
              ) : (
                <>
                  <Sparkles className="w-4 mr-2" />
                  Generate Email
                </>
              )}
            </Button>
            <div
              id="outputText"
              className="border border-gray-300 p-4 mt-4 text-sm min-h-[300px] overflow-y-auto"
              contentEditable={true}
              suppressContentEditableWarning={true}
              dangerouslySetInnerHTML={{ __html: formattedEmail }}
            />
            <div className="flex items-center justify-between gap-4 mt-4">
              <ToggleGroup
                size="sm"
                type="multiple"
                aria-label="Text formatting"
              >
                <ToggleGroupItem
                  value="bold"
                  aria-label="Toggle bold"
                  onClick={() => handleFormat("bold")}
                >
                  <Bold className="h-4 w-4" />
                </ToggleGroupItem>
                <ToggleGroupItem
                  value="italic"
                  aria-label="Toggle italic"
                  onClick={() => handleFormat("italic")}
                >
                  <Italic className="h-4 w-4" />
                </ToggleGroupItem>
                <ToggleGroupItem
                  value="underline"
                  aria-label="Toggle underline"
                  onClick={() => handleFormat("underline")}
                >
                  <Underline className="h-4 w-4" />
                </ToggleGroupItem>
              </ToggleGroup>
              <div className="flex items-center gap-2">
                <button
                  aria-label="Thumb Up"
                  className="text-gray-500 hover:text-gray-700"
                  onClick={() => handleEmailFeedback(true)}
                >
                  <ThumbsUp
                    className="w-5 h-5 mr-1"
                    fill={emailFeedback === true ? "currentColor" : "none"}
                  />
                </button>
                <button
                  aria-label="Thumb Down"
                  className="text-gray-500 hover:text-gray-700"
                  onClick={() => handleEmailFeedback(false)}
                >
                  <ThumbsDown
                    className="w-5 h-5 mr-1"
                    fill={emailFeedback === false ? "currentColor" : "none"}
                  />
                </button>
              </div>
            </div>
            <div className="flex justify-end mt-4 gap-2">
              <Button className="w-full sm:w-auto" onClick={copyToClipboard}>
                <Copy className="w-4 mr-2" />
                Copy to Clipboard
              </Button>
              <Button
                className="w-full sm:w-auto"
                onClick={() => setIsDialogOpen(true)}
                disabled={emailLoading || !outputText}
              >
                <Send className="w-4 mr-2" />
                Add Recipients
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
      <SendEmailDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        emailContent={document.getElementById("outputText") || ""}
        custcd={selectedAccount}
        custname={customerData?.custname}
      />
    </div>
  );
}
