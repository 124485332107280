import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { AppContext } from "contexts/AppContext";
import { v4 as uuidv4 } from "uuid";

export const ContactsContext = createContext();

export const ContactsProvider = ({ children }) => {
  const { salesAgentCD, BASE_URL, oktaId, token, custcd } =
    useContext(AppContext);
  const [contacts, setContacts] = useState([]);

  const fetchContacts = async () => {
    if (!salesAgentCD || !oktaId || !custcd) {
      console.warn("Missing required parameters");
      return [];
    }
    try {
      const params = new URLSearchParams({
        custcd,
        salesagentcd: salesAgentCD,
        oktaid: oktaId,
      });

      const response = await axios.get(`${BASE_URL}/get-contacts`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const activeContacts = response.data.filter(
        (contact) =>
          contact.contact_status !== "inactive" &&
          contact.contact_status !== "deleted"
      );

      // Sort contacts: primary first, then alphabetically by first name
      const sortedContacts = activeContacts.sort((a, b) => {
        // If one is primary and the other isn't, primary comes first
        if (a.is_primary && !b.is_primary) return -1;
        if (!a.is_primary && b.is_primary) return 1;

        // If neither is primary or both are primary, sort by first name
        const aName =
          typeof a.contact_name === "string"
            ? JSON.parse(a.contact_name)
            : a.contact_name;
        const bName =
          typeof b.contact_name === "string"
            ? JSON.parse(b.contact_name)
            : b.contact_name;

        return aName.contact_first_name.localeCompare(bName.contact_first_name);
      });

      setContacts(sortedContacts);
      console.log(sortedContacts);
      return sortedContacts;
    } catch (err) {
      console.error("Error fetching contacts:", err);
      setContacts([]);
      return [];
    }
  };

  const createContact = async (formData) => {
    try {
      const payload = {
        contact_id: uuidv4(),
        salesagentcd: salesAgentCD,
        custcd,
        oktaid: oktaId,
        is_primary: formData.is_primary,
        contact_name:
          typeof formData.contact_name === "string"
            ? JSON.parse(formData.contact_name)
            : formData.contact_name,
        contact_info:
          typeof formData.contact_info === "string"
            ? JSON.parse(formData.contact_info)
            : formData.contact_info,
        primary_contact_info:
          typeof formData.primary_contact_info === "string"
            ? JSON.parse(formData.primary_contact_info)
            : formData.primary_contact_info,
        contact_role: formData.contact_role,
        contact_status: "active",
        contact_notes: formData.contact_notes,
        created_date: new Date().toISOString(),
        digital_champion: formData.digital_champion,
      };

      const response = await axios.post(`${BASE_URL}/create-contact`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // If the new contact is set as primary, call set primary contact API
      if (formData.is_primary) {
        await setPrimaryContact(payload.contact_id);
      }

      await fetchContacts();
      return response;
    } catch (err) {
      console.error("Error creating contact:", err);
      throw err;
    }
  };

  const updateContact = async (formData) => {
    try {
      const payload = {
        salesagentcd: salesAgentCD,
        custcd,
        oktaid: oktaId,
        contact_id: formData.contact_id,
        is_primary: formData.is_primary,
        contact_name:
          typeof formData.contact_name === "string"
            ? JSON.parse(formData.contact_name)
            : formData.contact_name,
        contact_info:
          typeof formData.contact_info === "string"
            ? JSON.parse(formData.contact_info)
            : formData.contact_info,
        primary_contact_info:
          typeof formData.primary_contact_info === "string"
            ? JSON.parse(formData.primary_contact_info)
            : formData.primary_contact_info,
        contact_role: formData.contact_role,
        contact_status: "active",
        contact_notes: formData.contact_notes,
        digital_champion: formData.digital_champion,
        updated_date: new Date().toISOString(),
      };

      const response = await axios.post(`${BASE_URL}/update-contact`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // If the contact is set as primary, call set primary contact API
      if (formData.is_primary) {
        await setPrimaryContact(formData.contact_id);
      }

      await fetchContacts();
      return response;
    } catch (err) {
      console.error("Error updating contact:", err);
      throw err;
    }
  };

  const setPrimaryContact = async (contactId) => {
    try {
      const payload = {
        contact_id: contactId,
        salesagentcd: salesAgentCD,
        custcd,
        oktaid: oktaId,
        updated_date: new Date().toISOString(),
      };

      console.log("Setting primary contact payload:", payload);

      const response = await axios.post(
        `${BASE_URL}/set-primary-contact`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("Set Primary Contact Response:", response);
      await fetchContacts();

      // Log contacts after fetch to verify primary status
      console.log("Contacts after setting primary:", contacts);
    } catch (err) {
      console.error("Error setting primary contact:", err);
      throw err;
    }
  };

  const changeContactStatus = async (contactId, status) => {
    try {
      const payload = {
        contact_id: contactId,
        salesagentcd: salesAgentCD,
        custcd,
        oktaid: oktaId,
        contact_status: status,
        updated_date: new Date().toISOString(),
      };

      await axios.post(`${BASE_URL}/change-contact-status`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });

      await fetchContacts();
    } catch (err) {
      console.error("Error changing contact status:", err);
      throw err;
    }
  };

  const getPrimaryInfo = async ({ custcd: passedCustcd, salesagentcd, oktaid } = {}) => {
    const finalCustcd = passedCustcd || custcd;
    const finalSalesAgentCD = salesagentcd || salesAgentCD;
    const finalOktaId = oktaid || oktaId;
  
    const missingParams = [];
  
    if (!finalSalesAgentCD) missingParams.push("salesagentcd");
    if (!finalOktaId) missingParams.push("oktaid");
    if (!finalCustcd) missingParams.push("custcd");
  
    if (missingParams.length > 0) {
      console.warn(`Missing required parameters: ${missingParams.join(", ")}`);
      return null;
    }
  
    try {
      const params = new URLSearchParams({
        custcd: finalCustcd,
        salesagentcd: finalSalesAgentCD,
        oktaid: finalOktaId,
      });
  
      const response = await axios.get(`${BASE_URL}/get-primary-info`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // console.log(response.data);
      return response.data[0] || null;
    } catch (err) {
      console.error("Error fetching primary info:", err);
      return null;
    }
  };

  useEffect(() => {
    if (salesAgentCD && oktaId && custcd) {
      fetchContacts();
    } else {
      setContacts([]);
    }
  }, [salesAgentCD, oktaId, custcd]);

  return (
    <ContactsContext.Provider
      value={{
        contacts,
        createContact,
        updateContact,
        fetchContacts,
        changeContactStatus,
        setPrimaryContact,
        getPrimaryInfo,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};

export default ContactsProvider;
