"use client";

import { useState, useEffect, useContext } from "react";
import { ContractorModal } from "./components/contractor-modal";
import { ContractorCard } from "./components/contractor-card";
import { LeadSourceCarousel } from "./components/lead-source-carousel";
import { AddLeadModal } from "./components/add-lead-modal";
import { Button } from "../../components/ui/button";
import { PlusCircle, Check } from "lucide-react";
import { LeadGenContext } from "contexts/LeadGenContext";

// Helper function to safely parse numeric values
const safeParseFloat = (value, defaultValue = 0) => {
  if (!value) return defaultValue;
  const parsed = parseFloat(value);
  return isNaN(parsed) ? defaultValue : parsed;
};

// Move helper functions outside of the component
const parseCertifications = (certStr) => {
  if (!certStr) return [];

  try {
    const cleanStr = certStr.replace(/^["']|["']$/g, "");
    const withoutBrackets = cleanStr.slice(1, -1);

    return withoutBrackets
      .split(",")
      .map((item) => item.trim().replace(/^['"]|['"]$/g, ""))
      .filter(Boolean);
  } catch (error) {
    console.error("Error parsing certifications:", error);
    return [];
  }
};

const transformLeadData = (lead, index) => {
  // Safely parse numeric values
  const rating = safeParseFloat(lead.rating, 0).toFixed(1);
  const reviews = safeParseFloat(lead.reviews, 0);
  const score = safeParseFloat(lead.score, 0);

  return {
    id: index.toString(),
    post_link: lead.post_link || "",
    certifications: parseCertifications(lead.certifications),
    name: lead.name || "",
    description: lead.description || "",
    address: lead.address || "",
    analysis: lead.ai_insights || "",
    rating: rating,
    reviewCount: Math.round(reviews), // Round to nearest whole number
    phone: lead.phone || "",
    websitePreview: lead.website || "",
    source: lead.source || "",
    status: lead.lead_status || "Prospective Opportunity",
    email: lead.email || "",
    score: score,
  };
};

export default function Page() {
  const { leads } = useContext(LeadGenContext);
  const [selectedContractor, setSelectedContractor] = useState(null);
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [isAddLeadModalOpen, setIsAddLeadModalOpen] = useState(false);

  const sources = ["GAF", "Facebook - Places", "Direct"];
  const statuses = [
    "Prospective Opportunity",
    "Contacted",
    "Confirmed Lead",
    "Converted Customer",
  ];

  // Transform all leads with null checks
  const transformedLeads = (leads || []).map(transformLeadData);

  // Inside reduce before both filters
  const filteredLeads = sources.reduce((acc, source) => {
    const firstFilter = transformedLeads.filter((l) => l.source === source);

    acc[source] = firstFilter.filter(
      (l) =>
        selectedStatuses.length === 0 || selectedStatuses.includes(l.lead_status)
    );

    return acc;
  }, {});

  const toggleSource = (source) => {
    setSelectedSources((prev) =>
      prev.includes(source)
        ? prev.filter((s) => s !== source)
        : [...prev, source]
    );
  };

  const toggleStatus = (status) => {
    setSelectedStatuses((prev) =>
      prev.includes(status)
        ? prev.filter((s) => s !== status)
        : [...prev, status]
    );
  };

  const handleAddLead = (leadData) => {
    console.log("New lead added:", leadData);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white font-sans">
      <main className="container mx-auto py-6 px-4 sm:px-6 lg:px-8 space-y-8">
        <section className="space-y-4">
          <div className="space-y-4">
            <div className="flex flex-wrap gap-2">
              {sources.map((source) => (
                <Button
                  key={source}
                  variant="outline"
                  className={`relative pl-9 ${
                    selectedSources.includes(source)
                      ? "bg-blue-50 text-blue-600 border-blue-200 hover:bg-blue-100 hover:text-blue-700"
                      : "bg-white hover:bg-gray-50"
                  }`}
                  onClick={() => toggleSource(source)}
                >
                  <span
                    className={`absolute left-2 top-1/2 transform -translate-y-1/2 w-5 h-5 border rounded-sm flex items-center justify-center ${
                      selectedSources.includes(source)
                        ? "bg-blue-600 border-blue-600"
                        : "border-gray-300"
                    }`}
                  >
                    {selectedSources.includes(source) && (
                      <Check className="w-4 h-4 text-white" />
                    )}
                  </span>
                  {source}
                </Button>
              ))}
            </div>
            <div className="space-y-4">
              <h2 className="text-xl font-semibold text-gray-800">
                Lead Status
              </h2>
              <div className="flex flex-wrap gap-2">
                {statuses.map((status) => (
                  <Button
                    key={status}
                    variant="outline"
                    className={`relative pl-9 ${
                      selectedStatuses.includes(status)
                        ? "bg-blue-50 text-blue-600 border-blue-200 hover:bg-blue-100 hover:text-blue-700"
                        : "bg-white hover:bg-gray-50"
                    }`}
                    onClick={() => toggleStatus(status)}
                  >
                    <span
                      className={`absolute left-2 top-1/2 transform -translate-y-1/2 w-5 h-5 border rounded-sm flex items-center justify-center ${
                        selectedStatuses.includes(status)
                          ? "bg-blue-600 border-blue-600"
                          : "border-gray-300"
                      }`}
                    >
                      {selectedStatuses.includes(status) && (
                        <Check className="w-4 h-4 text-white" />
                      )}
                    </span>
                    {status}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        </section>

        {selectedSources.length > 0 ? (
          <section className="space-y-6">
            <div className="space-y-8">
              {selectedSources.map((source) => (
                <div key={source} className="bg-white p-6 rounded-lg shadow-sm">
                  <LeadSourceCarousel
                    source={source}
                    leads={filteredLeads[source] || []}
                    onLeadClick={(id) => setSelectedContractor(id)}
                  />
                </div>
              ))}
            </div>
          </section>
        ) : (
          <section className="space-y-6">
            <h2 className="text-2xl font-semibold text-gray-800">
              Recent Leads
            </h2>
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
              {transformedLeads
                .filter(
                  (lead) =>
                    selectedStatuses.length === 0 ||
                    selectedStatuses.includes(lead.status)
                )
                .map((contractor) => (
                  <ContractorCard
                    key={contractor.id}
                    {...contractor}
                    onClick={() => setSelectedContractor(contractor.id)}
                  />
                ))}
            </div>
          </section>
        )}
      </main>

      <ContractorModal
        isOpen={!!selectedContractor}
        onClose={() => setSelectedContractor(null)}
        contractorId={selectedContractor}
        contractors={transformedLeads}
      />

      <AddLeadModal
        isOpen={isAddLeadModalOpen}
        onClose={() => setIsAddLeadModalOpen(false)}
        onSubmit={handleAddLead}
      />
    </div>
  );
}
