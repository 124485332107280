export const getAccessToken = (testing) => {
    if (testing) return "eyJraWQiOiJLTHpSQmN1XzVySHpmUVV2NXo5Q25CR0NpV1RrZ1g4ZEFrNjBKd3lTZ1djIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHUxMHZndW5vbXZ4cVEzaDJwOCIsIm5hbWUiOiJOYWRpYSBIb3NzYWluIiwiZW1haWwiOiJOYWRpYS5Ib3NzYWluQHNyc2Rpc3RyaWJ1dGlvbi5jb20iLCJ2ZXIiOjEsImlzcyI6Imh0dHBzOi8vc3JzLm9rdGEuY29tL29hdXRoMi9hdXN2b2pndjl4dnpDU0RpWDJwNyIsImF1ZCI6IjBvYXY2eWFkMDdSa3NlNzVaMnA3IiwiaWF0IjoxNzM5Mzc0MTkxLCJleHAiOjE3MzkzNzc3OTEsImp0aSI6IklELlZNZ1FxSnp1VU91eUU5N0t6ZDhPREV6RzctTExiTXdROVV5eDREVzNvNUkiLCJhbXIiOlsibWZhIiwib3RwIiwicHdkIl0sImlkcCI6IjAwbzE2emp3ZWNDRnU3cFVqMnA3Iiwibm9uY2UiOiIxWjRhOU9hZlo1dnVyUGxMMzJ5WEJaTzQxSDVQUVZoaHJsV3doVmVHTXJJTU5lVW5wSlFIZjJoVkxQNEQ2dXI1IiwicHJlZmVycmVkX3VzZXJuYW1lIjoiTkgxMTAwMzZAbW1oZmdiLmNvbSIsImF1dGhfdGltZSI6MTczOTM3NDE5MCwiYXRfaGFzaCI6IkoyajJGZWR3ZlNtaUdmZWZfc1N4aXcifQ.VJMMLanF3LXG75p-OWpq4UfRx8lydtp8kutJMoJ_6DkObg_N3-ZX4e2k46tcjNRCH3gAOw0pJRjoFd3-RVJTphlfMYfBULEzXMp9KWZkJlbfYMAl5smNeb2DZ_89nTHz3JWK76ktvl-ZotevBdn1D5hCWLXCfz4Kv9lJ8Tw6HotgT4Fv_CCUlU8rqPKrln9yV0rDCz4VOvK7rp9eu8-spb98y_XyJI2rzneCaUNpJ9uA9rekaQXEq6hVZ0nXPmCQvf87IGCut_Hmrq58R04yEMvkeHScqBU72mGKt-uvLHFTG0O-B1Y-Bo4FimTCDmx3ERz9VAnxFVLU72h4UKQyGA"
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
    return oktaTokenStorage?.idToken?.idToken;
};

export const getLoggedInUser = async (testing) => {
  if (testing) return { userId: 'CC106472', firstName: 'Test', lastName: 'User' };

  const retryDelay = 500;
  const maxRetries = 5;

  // Helper function to wait for a specified duration
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // Function to attempt fetching user data with retries
  const fetchFromOktaTokenStorage = async (retries) => {
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));

    // Ensure tokens are ready
    if (!oktaTokenStorage || !oktaTokenStorage.idToken) {
      if (retries > 0) {
        await delay(retryDelay); // Wait before retrying
        return fetchFromOktaTokenStorage(retries - 1); // Retry fetching
      }
      return null; // Return null if all retries are exhausted
    }

    const preferredUsername = oktaTokenStorage.idToken.claims?.preferred_username;
    const name = oktaTokenStorage.idToken.claims?.name;

    if (preferredUsername && name) {
      const [firstPart] = preferredUsername.split('@');
      const [firstName, lastName] = name.split(' ');
      return { userId: firstPart, firstName: firstName || '', lastName: lastName || '' };
    }

    return null;
  };

  return await fetchFromOktaTokenStorage(maxRetries);
};
